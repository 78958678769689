// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/object';

async function get(category) {
	let params = {
		category: category,
	};
	return axiosApi.get(`${urlPath}`, { params });
}

async function getOne(id) {
	return axiosApi.get(`${urlPath}/${id}`);
}

async function save(imageId, name, category) {
	let params = {
		imageId: imageId,
		name: name,
		category: category,
	};
	return axiosApi.post(`${urlPath}`, params);
}

async function remove(id) {
	return axiosApi.delete(`${urlPath}/${id}`);
}

async function isUseObjectPin(objectId) {
	return axiosApi.get(`${urlPath}/isUse/${objectId}`);
}

export default { get, getOne, save, remove, isUseObjectPin };
