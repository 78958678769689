<template>
	<!-- 팝업 -->
	<div :class="objectListLayer" id="layer01">
		<div class="inner" style="width:1280px; height:100vh">
			<strong class="tit">오브제 </strong>
			<div class="cont" style="height:100% !important">
				<div class="objectList">
					<ul>
						<li :class="menuClass[index]" v-for="(menu, index) in menus" :key="menu.code">
							<a @click="onGetObjetList(menu.code, index)">{{ menu.name }} 오브제</a>
							<ul :style="`display:${menuStyle[index]}`">
								<li v-for="objet in objetList" :key="objet.id" @click="selectObject(objet)" style="cursor: pointer;">
									<span
										v-if="objet.imageId != null"
										:style="`background: #fff url(/file/${objet.imageId})no-repeat center 25%/47% auto;`"
									>
										{{ objet.name }}
										<a class="btnDel" v-if="objet.creator == userId" @click.stop.prevent="onDeleteObjet(objet.id)"
											>삭제</a
										>
									</span>
									<span
										v-else
										:style="`background: #fff url(/resources/images/no-img02.svg)no-repeat center 25%/47% auto;`"
									>
										{{ objet.name }}
										<a class="btnDel" v-if="objet.creator == userId" @click.stop.prevent="onDeleteObjet(objet.id)"
											>삭제</a
										>
									</span>
								</li>
								<li>
									<a class="btnAdd" @click="openObjetLayer()" name="layerObjet">추가하기</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<a href="javascript:void(0)" name="layer01" @click="closeObjectLayerList()">닫기</a>
		</div>
		<!-- 팝업 -->
		<div :class="objectLayerClass" id="layerObjet" name="layerObjet">
			<div class="inner" style="width:400px">
				<strong class="tit">오브제 등록</strong>
				<div class="cont">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>오브제명 <em>*</em></dt>
							<dd>
								<input
									type="text"
									title="오브제명"
									placeholder="오브제명을 입력해주세요."
									required="required"
									v-model="objetName"
									maxlength="254"
								/>
							</dd>
						</dl>
						<dl>
							<dt>이미지 <em>*</em></dt>
							<dd>
								<div class="imgFile">
									<input
										type="text"
										title="이미지 등록"
										placeholder="이미지를 등록해주세요."
										required="required"
										v-model="fileName"
										readonly
									/>
									<input type="file" title="파일 선택" id="file" accept="image/*" @change="imageUpload" />
								</div>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="onSaveObjet()" :disabled="!saveBtn">저장</button>
					</div>
					<!--// btnArea -->
				</div>
				<a name="layerObjet" @click="closeObjetLayer()">닫기</a>
			</div>
		</div>
		<!-- 팝업 -->
	</div>
	<!-- 팝업 -->
</template>
<script>
import objet from '@/api/objet';
import code from '@/api/code';
import file from '@/api/file';
import { showSpinner, hideSpinner, getAuth, isImage } from '@/utils/cmm';
export default {
	mounted() {
		this.onGetMenu();
		let auth = getAuth();
		this.userId = auth.id;
	},
	props: {
		value: {},
	},
	data() {
		return {
			groupCode: 'OBCA',
			buildingId: '',
			userId: '',
			menus: [],
			menuClass: ['curr'],
			menuStyle: ['block'],
			menuIdx: 0,
			objetList: [],
			objectLayerClass: 'layer1 layerPop',
			objetName: '',
			objetCategory: '',
			files: [],
			fileName: null,
			objectListLayer: 'layerObject layerPop',
			resolveHandler: null,
			deleteObjectIds: [],
		};
	},
	computed: {
		saveBtn() {
			return this.objetName.length > 0;
		},
	},
	methods: {
		async onGetMenu() {
			try {
				showSpinner();
				let codeList = await code.getDetail(this.groupCode);
				this.menus = codeList.data;
				this.objetCategory = this.menus[this.menuIdx].code;
				let objetList = await objet.get(this.objetCategory);
				this.objetList = objetList.data;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onGetObjetList(category, index) {
			try {
				let objetList = await objet.get(category);
				this.objetList = objetList.data;
				// eslint-disable-next-line no-unused-vars
				this.menuClass = this.menuClass.map(e => (e = ''));
				// eslint-disable-next-line no-unused-vars
				this.menuStyle = this.menuStyle.map(e => (e = ''));
				this.menuClass[index] = 'curr';
				this.menuStyle[index] = 'block';
				this.menuIdx = index;
				this.objetCategory = category;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async onSaveObjet() {
			if (this.objetName.trim().length == 0) {
				this.objetName = this.objetName.trim();
				return await this.$alert('오브제 이름을 입력해주세요.');
			}

			try {
				showSpinner();
				let res = null;
				let imageId = null;
				if (this.files.length > 0) {
					res = await file.upload(this.files);
					imageId = res.data[0].id;
				}
				await objet.save(imageId, this.objetName, this.objetCategory);
				this.onGetObjetList(this.objetCategory, this.menuIdx);
				this.closeObjetLayer();
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				this.files = [];
				hideSpinner();
			}
		},
		async onDeleteObjet(id) {
			if (!(await this.$confirm('선택하신 오브제를 삭제하시겠습니까?', '오브제 안내'))) return false;
			// 현재 오브제로 사용하고 있는 오브제핀이 존재하는지 여부 확인
			try {
				showSpinner();
				await objet.isUseObjectPin(id);
			} catch (ex) {
				if (ex.status === 2040) {
					if (
						!(await this.$confirm(
							'선택하신 오브제는 사용중 입니다.' +
								'<br/>삭제하실 경우 사용중인' +
								'<br/>오브제핀은 일괄 삭제됩니다.' +
								'<br/>삭제하시겠습니까?',
							'오브제 안내',
						))
					)
						return false;
				}
			} finally {
				hideSpinner();
			}
			try {
				showSpinner();
				await objet.remove(id);
				this.deleteObjectIds.push(id);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			this.onGetObjetList(this.objetCategory, this.menuIdx);
		},
		openObjetLayer() {
			this.objectLayerClass += ' on';
		},
		closeObjetLayer() {
			this.objetName = '';
			this.files = [];
			this.fileName = null;
			this.objectLayerClass = 'layer1 layerPop';
		},
		imageUpload(e) {
			if (e.target.files[0] != undefined) {
				this.files = [];
				if (!isImage(e.target.files[0])) {
					this.$alert(`이미지는 png, jpg, jpeg, bmp의 확장자 파일만 올려주세요.`);
					return false;
				}
				this.files.push(e.target.files[0]);
				this.fileName = this.files[0].name;
			}
			document.querySelector('#file').value = '';
		},
		openObjectLayerList() {
			this.deleteObjectIds = [];
			this.objectListLayer = 'layerObject layerPop on';
			if (this.resolveHandler) {
				return Promise.reject();
			}

			return new Promise(resolve => {
				this.resolveHandler = resolve;
			});
		},
		closeObjectLayerList(value = null) {
			this.objectListLayer = 'layerObject layerPop';
			if (!this.resolveHandler) return;
			this.resolveHandler({ deleteObjectIds: this.deleteObjectIds, selectObject: value });
			this.resolveHandler = null;
		},
		selectObject(objet) {
			this.closeObjectLayerList(objet);
		},
	},
};
</script>
<style scoped>
.imgFile input:read-only {
	background-color: white;
}
</style>
