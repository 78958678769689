<template>
	<div>
		<!-- container -->
		<div>
			<!-- titArea -->
			<div class="titArea">
				<div class="inner">
					<div class="editArea">
						<a href="javascript:void(0)" :class="{ btnAddObjectArea: true, active: 2 === type }" @click="onAddObjet">
							오브제영역 추가
						</a>
					</div>
					<a href="javascript:void(0)" class="btnEdit v5" v-show="1 === type && selected" @click="onRemoveObjet"
						>삭제</a
					>
				</div>
			</div>
			<!--// titArea -->
			<!-- floorPlan -->
			<div class="floorPlan" ref="floorPlan">
				<div style="position: relative; overflow: hidden; height:100%;">
					<img
						v-if="imageUrl"
						ref="imgRoom"
						:style="{ position: 'absolute', top: `${imageY}px`, left: `${imageX}px` }"
						:src="imageUrl"
						:width="imageWidth * scale"
						:height="imageHeight * scale"
					/>
					<svg
						v-if="imageUrl"
						xmlns="http://www.w3.org/2000/svg"
						version="1.2"
						baseProfile="tiny"
						width="100%"
						height="100%"
						style="top:0; left: 0;"
					>
						<g>
							<rect
								v-if="'rect' === roomData.type"
								:x="roomData.x * scale + imageX"
								:y="roomData.y * scale + imageY"
								:width="roomData.width * scale"
								:height="roomData.height * scale"
								rx="8"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
							<circle
								v-if="'circle' === roomData.type"
								:cx="roomData.cx * scale + imageX"
								:cy="roomData.cy * scale + imageY"
								:r="roomData.r * scale"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
							<polygon
								v-if="'polygon' === roomData.type"
								:points="
									roomData.points.map((it, idx) => (idx % 2 ? it * scale + imageY : it * scale + imageX)).join(' ')
								"
								:fill="roomFill"
								:stroke="roomStroke"
								stroke-width="0"
							/>
						</g>
					</svg>
					<svg
						v-if="imageUrl"
						xmlns="http://www.w3.org/2000/svg"
						version="1.2"
						baseProfile="tiny"
						ref="svgEditor"
						width="100%"
						height="100%"
						style="top:0; left: 0;"
						@mousedown.capture="onMousedown"
						@mousemove.capture="onMousemove"
						@mouseup.capture="onMouseup"
						@mouseleave="onMouseleave"
						@touchstart="onTouchstart"
						@touchmove="onTouchmove"
						@touchend="onTouchend"
					>
						<g class="_draw" v-for="(item, idx) in objectPins" :key="idx" :data-idx="idx">
							<rect
								:x="item.x * scale + imageX"
								:y="item.y * scale + imageY"
								:width="item.width * scale"
								:height="item.height * scale"
								:fill="pinFill"
								:stroke="pinStroke"
								stroke-width="1"
							/>
							<path
								:transform="
									`translate(${(item.x + item.width / 2) * scale + imageX - 5}, ${(item.y + item.height / 2) * scale +
										imageY -
										24})`
								"
								d="M12.791,12.893,15.375,4.95a3.38,3.38,0,0,0,2.377-1.734L9.878,0a3.4,3.4,0,0,0,.469,2.925l-3.7,7.458A9.32,9.32,0,0,0,0,12.955L7.479,16.01,4.12,24.234l.778.318,3.359-8.224,7.479,3.054a9.32,9.32,0,0,0-2.945-6.489"
								fill="#0c234c"
							/>
							<g class="_helper" v-show="1 === type && selected === item">
								<rect
									class="_helper"
									:x="item.x * scale + imageX - helperHalfSize"
									:y="item.y * scale + imageY - helperHalfSize"
									:width="helperSize"
									:height="helperSize"
									:fill="pinStroke"
									data-helper="0"
								></rect>
								<rect
									class="_helper"
									:x="(item.x + item.width) * scale + imageX - helperHalfSize"
									:y="item.y * scale + imageY - helperHalfSize"
									:width="helperSize"
									:height="helperSize"
									:fill="pinStroke"
									data-helper="1"
								></rect>
								<rect
									class="_helper"
									:x="item.x * scale + imageX - helperHalfSize"
									:y="(item.y + item.height) * scale + imageY - helperHalfSize"
									:width="helperSize"
									:height="helperSize"
									:fill="pinStroke"
									data-helper="2"
								></rect>
								<rect
									class="_helper"
									:x="(item.x + item.width) * scale + imageX - helperHalfSize"
									:y="(item.y + item.height) * scale + imageY - helperHalfSize"
									:width="helperSize"
									:height="helperSize"
									:fill="pinStroke"
									data-helper="3"
								></rect>
							</g>
						</g>
					</svg>
				</div>
				<div class="btnZoomArea" style="z-index:100;">
					<div class="inBox">
						<a href="javascript:void(0)" @click="onZoomin">확대</a>
						<a href="javascript:void(0)" @click="onZoomout">축소</a>
					</div>
				</div>
			</div>
			<!--// floorPlan -->
			<!-- inner -->
			<div class="inner">
				<!-- btnBottom -->
				<div class="btnBottom">
					<button type="button" class="btn btnGray" @click="onPrev">취소</button>
					<button type="button" class="btn" @click="onSave">저장</button>
				</div>
				<!--// btnBottom -->
			</div>
			<!--// inner -->
			<ObjetList ref="objectList"></ObjetList>
			<!--// container -->
		</div>
	</div>
</template>
<script>
import { showSpinner, hideSpinner, hideMenu } from '@/utils/cmm';
import { sizeOfImage } from '@/utils/cmm';
import room from '@/api/room';
import floorplan from '@/api/floorplan';
import objectpin from '@/api/objectpin';
import ObjetList from '../building/objetList';

const SCALE_UNIT = 0.1;

export default {
	components: { ObjetList },
	data() {
		return {
			// roomId: Number(this.$route.params.id),
			roomIds: this.$route.params.id,
			floorPlanId: Number(this.$route.params.floorPlanId),
			imageId: Number(this.$route.params.imageId),
			imageUrl: null,
			imageX: 0,
			imageY: 0,
			imageWidth: null,
			imageHeight: null,
			helperSize: 14,
			helperHalfSize: 7,
			pinFill: '#EA5B5B33',
			pinStroke: '#EA5B5B',
			roomFill: '#0c234c33',
			roomStroke: '#0c234c',
			scale: 1.0,
			roomData: {},
			objectPins: [],
			deletedObjectPins: [],
			isDrag: false,
			type: 0, // 0: none, 1: select, 2: add object
			pointer: null,
			beginPointer: null,
			selected: null,
			helperIdx: -1,
			selectObject: null,
		};
	},
	computed: {},
	watch: {},
	created() {
		// this.roomIds = this.$route.params.id.map(Number);
		// this.roomIds = this.$route.params.id;
		// console.log('incomming roomIds in editor page created');
		// console.log(this.roomIds);
		hideMenu();
		this.reload();
	},
	methods: {
		async onPrev() {
			if (
				!(await this.$confirm(
					'현재 작업 중인 데이터가 취소됩니다.\r\n작업 취소를 진행하시겠습니까?',
					'오브제 설정 안내',
				))
			) {
				return;
			}
			this.done();
		},
		onAddObjet() {
			this.type = 2 === this.type ? 0 : 2;
		},
		onRemoveObjet() {
			if (!this.selected) return;
			const selected = this.selected;
			this.selected = null;
			const idx = this.objectPins.indexOf(selected);
			if (~idx) {
				if (selected['id']) {
					this.deletedObjectPins.push(selected.id);
				}
				this.objectPins.splice(idx, 1);
			}
		},
		// async onSave() {
		// 	try {
		// 		showSpinner();

		// 		const req = { id: this.roomData.id };
		// 		req.objectPins = this.objectPins.map(it => ({
		// 			id: it.id,
		// 			objectId: it.objectId,
		// 			areaInfo: `${Math.round(it.x)} ${Math.round(it.y)} ${Math.round(it.width)} ${Math.round(it.height)}`,
		// 		}));
		// 		req.deletedObjectPins = this.deletedObjectPins;
		// 		await room.update(req);
		// 	} catch (ex) {
		// 		await this.$alert(`오브제핀저장 실패했습니다.\r\n${ex.message}`);
		// 	} finally {
		// 		hideSpinner();
		// 	}
		// 	this.done();
		// },

		// 		{
		//   "id": 0,
		//   "floorPlanId": 0,
		//   "floorName": "string",
		//   "tenantId": 0,
		//   "tenantName": "string",
		//   "name": "string",
		//   "ownership": "string",
		//   "shapeType": "string",
		//   "shapeInfo": "string",
		//   "objectPins": [
		//     {
		//       "id": 0,
		//       "objectId": 0,
		//       "roomId": 0,
		//       "areaInfo": "string",
		//       "isUse": true,
		//       "memoCount": 0,
		//       "roomName": "string",
		//       "objectName": "string",
		//       "fileId": 0,
		//       "category": "string"
		//     }
		//   ],
		//   "deletedObjectPins": [
		//     0
		//   ],
		//   "creator": 0
		// }

		async onSave() {
			try {
				showSpinner();
				const req = {
					id: this.roomData.id,
					floorPlanId: this.roomData.floorPlanId,
					floorName: 'string',
					tenantId: 0,
					tenantName: 'string',
					name: 'string',
					ownership: 'string',
					shapeType: 'string',
					shapeInfo: 'string',
				};
				req.objectPins = this.objectPins.map(it => ({
					id: it.id,
					objectId: it.objectId,
					areaInfo: `${Math.round(it.x)} ${Math.round(it.y)} ${Math.round(it.width)} ${Math.round(it.height)}`,
				}));
				req.deletedObjectPins = this.deletedObjectPins;

				// console.log('on save pass req data');
				// console.log(req);
				await room.update(req);
			} catch (ex) {
				await this.$alert(`오브제핀저장 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
			this.done();
		},

		onMousedown(ev) {
			const { target, clientX, clientY } = ev;
			const { left, top } = this.$refs.svgEditor.getBoundingClientRect();
			const pointer = { x: clientX - left, y: clientY - top };

			this.isDrag = true;
			if (1 == this.type) {
				if ('svg' === target.tagName) {
					this.isMoving = true;
				} else if (this.selected) {
					this.helperIdx = target.dataset.helper;
				}
			} else if (2 == this.type) {
				const pin = {
					name: '',
					x: (pointer.x - this.imageX) / this.scale,
					y: (pointer.y - this.imageY) / this.scale,
					width: 0,
					height: 0,
				};
				this.objectPins.push(pin);
				this.selected = pin;
			} else {
				this.isMoving = true;
			}

			if (this.isMoving) {
				ev.preventDefault();
			}
			this.pointer = pointer;
			this.beginPointer = pointer;
		},
		onMousemove({ clientX, clientY }) {
			if (!this.isDrag) return;
			const { left, top } = this.$refs.svgEditor.getBoundingClientRect();
			const pointer = { x: clientX - left, y: clientY - top };
			const dx = pointer.x - this.pointer.x;
			const dy = pointer.y - this.pointer.y;

			const selected = this.selected;
			const dx0 = dx / this.scale;
			const dy0 = dy / this.scale;
			if (this.isMoving) {
				this.moveImgRoom(dx, dy);
			} else if (1 === this.type) {
				if (selected) {
					switch (this.helperIdx) {
						case '0':
							selected.width = Math.max(selected.width - dx0, 1);
							selected.height = Math.max(selected.height - dy0, 1);
							if (1 < selected.width) {
								selected.x += dx0;
							}
							if (1 < selected.height) {
								selected.y += dy0;
							}
							break;
						case '1':
							selected.width = Math.max(selected.width + dx0, 1);
							selected.height = Math.max(selected.height - dy0, 1);
							if (1 < selected.height) {
								selected.y += dy0;
							}
							break;
						case '2':
							selected.width = Math.max(selected.width - dx0, 1);
							selected.height = Math.max(selected.height + dy0, 1);
							if (1 < selected.width) {
								selected.x += dx0;
							}
							break;
						case '3':
							selected.width = Math.max(selected.width + dx0, 1);
							selected.height = Math.max(selected.height + dy0, 1);
							break;
						default:
							selected.x += dx0;
							selected.y += dy0;
							break;
					}
				}
			} else if (2 === this.type) {
				if (selected) {
					selected.x = (Math.min(this.beginPointer.x, pointer.x) - this.imageX) / this.scale;
					selected.y = (Math.min(this.beginPointer.y, pointer.y) - this.imageY) / this.scale;
					selected.width = Math.abs(this.beginPointer.x - pointer.x) / this.scale;
					selected.height = Math.abs(this.beginPointer.y - pointer.y) / this.scale;
				}
			}
			this.pointer = pointer;
		},
		async onMouseup({ target }) {
			this.isDrag = false;
			this.isMoving = false;

			const isSamePointer = this.pointer
				? this.beginPointer.x === this.pointer.x && this.beginPointer.y === this.pointer.y
				: true;

			if (2 === this.type) {
				if (isSamePointer) {
					this.onRemoveObjet();
				} else if (this.selected) {
					const res = await this.$refs.objectList.openObjectLayerList();
					if (res) {
						if (res.selectObject) {
							this.selected.objectId = res.selectObject.id;
						} else {
							this.onRemoveObjet();
						}
						if (res.deleteObjectIds) {
							this.objectPins = this.objectPins.filter(it => !~res.deleteObjectIds.indexOf(it.objectId));
						}
					} else {
						this.onRemoveObjet();
					}
				}
			} else {
				if ('svg' === target.tagName) {
					if (isSamePointer && 1 === this.type && this.selected) {
						this.selected = null;
					}
				} else {
					const parent = target.parentNode;
					if ('g' === parent.tagName && parent.classList.contains('_draw')) {
						this.selected = this.objectPins[parent.dataset.idx];
						this.type = 1;
					}
				}
			}

			this.beginPointer = null;
		},
		onMouseleave() {
			this.isDrag = false;
		},
		onTouchstart(ev) {
			ev.preventDefault();

			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousedown', { clientX, clientY }));
		},
		onTouchmove(ev) {
			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousemove', { clientX, clientY }));
		},
		onTouchend({ target }) {
			this.onMouseup({ target });
		},
		onZoomin() {
			const scale = this.scale;
			this.scale += this.scale * SCALE_UNIT;
			const ratio = this.scale / scale;
			const { offsetWidth, offsetHeight } = this.$refs.floorPlan;
			this.imageX -= (offsetWidth - this.imageX * 2) * (ratio - 1) * 0.5;
			this.imageY -= (offsetHeight - this.imageY * 2) * (ratio - 1) * 0.5;
		},
		onZoomout() {
			const scale = this.scale;
			//this.scale = Math.max(SCALE_UNIT, this.scale - SCALE_UNIT);
			this.scale -= this.scale * SCALE_UNIT;
			const ratio = this.scale / scale;
			const { offsetWidth, offsetHeight } = this.$refs.floorPlan;
			this.imageX -= (offsetWidth - this.imageX * 2) * (ratio - 1) * 0.5;
			this.imageY -= (offsetHeight - this.imageY * 2) * (ratio - 1) * 0.5;
		},
		done() {
			this.$emit('done', {
				// roomIds: this.roomIds,
				// type: this.roomData.type,
				// floorPlanId: this.roomData.floorPlanId,
				floorId: this.roomData.floorId,
				// floorId: this.floorPlanId,
			});
		},
		async reload() {
			try {
				showSpinner();
				// console.log('editor object page this. room ids');
				// console.log(this.roomIds);
				// console.log('this.floorPlanId', this.floorPlanId);
				// console.log(this.roomIds[this.floorPlanId]);

				let rooms = this.roomIds[this.floorPlanId];
				// console.log('rooms');
				// console.log(rooms);

				let resFloorplan = await floorplan.getOne(this.floorPlanId);
				// console.log('resFloorplan');
				// console.log(resFloorplan);
				const imgSrc = `/file/${resFloorplan.imageId}`;
				// const imgSrc = `/file/${this.imageId}`;
				const sizeImg = await sizeOfImage(imgSrc);
				this.imageWidth = sizeImg.width;
				this.imageHeight = sizeImg.height;

				const width = this.$refs.floorPlan.offsetWidth;
				const height = this.$refs.floorPlan.offsetHeight;
				const padding = Math.min(width * 0.1, height * 0.1);

				if (resFloorplan.imageId) {
					this.imageUrl = imgSrc;
				}

				for (let rId of rooms) {
					// console.log('rId');
					// console.log(rId);

					let resRoom = await room.getOne(rId);
					// console.log('room output');
					// console.log(resRoom);
					let roomData = {
						id: resRoom.id,
						type: resRoom.shapeType,
						name: resRoom.name,
					};
					const vals = resRoom.shapeInfo.split(' ').map(it => Number(it));
					switch (resRoom.shapeType) {
						case 'rect':
							Object.assign(roomData, {
								x: vals[0],
								y: vals[1],
								width: vals[2],
								height: vals[3],
								area: {
									x: vals[0],
									y: vals[1],
									width: vals[2],
									height: vals[3],
								},
							});
							break;
						case 'circle':
							Object.assign(roomData, {
								cx: vals[0],
								cy: vals[1],
								r: vals[2],
								area: {
									x: vals[0] - vals[2],
									y: vals[1] - vals[2],
									width: vals[2] * 2,
									height: vals[2] * 2,
								},
							});
							break;
						case 'polygon':
							{
								let result = [
									[vals[0], vals[0]],
									[vals[1], vals[1]],
								];
								for (let i = 2; i < vals.length; i += 2) {
									result[0][0] = Math.min(result[0][0], vals[i]);
									result[0][1] = Math.max(result[0][1], vals[i]);
									result[1][0] = Math.min(result[1][0], vals[i + 1]);
									result[1][1] = Math.max(result[1][1], vals[i + 1]);
								}
								Object.assign(roomData, {
									points: vals,
									area: {
										x: result[0][0],
										y: result[1][0],
										width: result[0][1] - result[0][0],
										height: result[1][1] - result[1][0],
									},
								});
							}
							break;
					}
					// console.log('room output out');
					// console.log('new RoomData');
					// console.log(roomData);

					// let resFloorplan = await floorplan.getOne(resRoom.floorPlanId);
					// roomData.floorPlanId = resRoom.floorPlanId;
					// roomData.floorId = resFloorplan.floorId;
					// let resFloorplan = await floorplan.getOne(this.floorPlanId);

					// console.log('this floorId', this.floorId);
					// console.log('this roomid', this.roomid);

					roomData.floorPlanId = this.floorPlanId;
					roomData.floorId = resFloorplan.floorId;

					let resObjectPin = await objectpin.get({ roomId: rId });
					// console.log('resObjectPin');
					// console.log(resObjectPin);
					const newObjectPins =
						resObjectPin.data &&
						resObjectPin.data
							.map(it => {
								const vals = it.areaInfo.split(' ').map(it => Number(it));
								if (4 !== vals.length || isNaN(vals[0]) || isNaN(vals[1]) || isNaN(vals[2]) || isNaN(vals[3])) {
									console.error('objectpin is invalid format:', it.areaInfo);
									return null;
								}
								return {
									id: it.id,
									objectId: it.objectId,
									isUse: it.isUse,
									x: vals[0],
									y: vals[1],
									width: vals[2],
									height: vals[3],
								};
							})
							.filter(it => !!it && it.isUse);

					if (this.objectPins && newObjectPins) {
						// console.log('newObjectPins');
						// console.log(newObjectPins);
						this.objectPins = this.objectPins.concat(newObjectPins);
						// console.log('final objectPins');
						// console.log(this.objectPins);
					} else {
						// this.objectPins = newObjectPins || [];
						// console.log('first objectPins');
						// console.log(this.objectPins);
					}

					if (roomData.area) {
						const scaleX = (width - padding * 2) / roomData.area.width;
						const scaleY = (height - padding * 2) / roomData.area.height;
						if (scaleX < scaleY) {
							const offsetY = (roomData.area.y + roomData.area.height * 0.5) * scaleX - height * 0.5;
							this.imageX = -(roomData.area.x * scaleX - padding);
							this.imageY = -offsetY;
							this.scale = scaleX;
						} else {
							const offsetX = (roomData.area.x + roomData.area.width * 0.5) * scaleY - width * 0.5;
							this.imageX = -offsetX;
							this.imageY = -(roomData.area.y * scaleY - padding);
							this.scale = scaleY;
						}
					} else {
						const width = this.$refs.floorPlan.offsetWidth;
						const height = this.$refs.floorPlan.offsetHeight;
						this.scale = Math.min(width / this.imageWidth, height / this.imageHeight);
						this.imageX = (width - this.imageWidth * this.scale) * 0.5;
						this.imageY = (height - this.imageHeight * this.scale) * 0.5;
					}

					// this.imageUrl = imgSrc;
					// console.log('final roomData');
					// console.log(roomData);
					// console.log('final objectPins');
					// console.log(this.objectPins);
					this.roomData = roomData;
					// this.objectPins = objectPins || [];
				}
			} catch (ex) {
				console.error(ex);
				await this.$alert(`실정보 조회 실패했습니다.\r\n${ex.message}`);
				this.$router.go(-1);
				// this.done();
			} finally {
				hideSpinner();
			}
		},
		moveImgRoom(dx, dy) {
			const img = this.$refs.imgRoom;
			let offsetLeft = img.offsetLeft + dx;
			let offsetTop = img.offsetTop + dy;
			this.imageX = offsetLeft;
			this.imageY = offsetTop;
		},
		getObject(selectObject) {
			this.selectObject = selectObject;
		},
	},
};
</script>

<style scoped></style>
